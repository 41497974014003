import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import { Switch, Route, HashRouter } from "react-router-dom";
import "../importer/jquery-import";
import "../importer/breakpoint-import";
import "../importer/browser-import";
import "../importer/scrolly-import";
import "../importer/dropotron-import";
import "../importer/scrollex-import";
import messages_en from "./i18n/messages_en.json";
import messages_fr from "./i18n/messages_fr.json";
import messages_lu from "./i18n/messages_lu.json";
import Home from "./pages/Home";
import Tikki from "./pages/Tikki";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PrivacyNotice from "./pages/PrivacyNotice";
import Mail from "./pages/Mail";
import Blog from "./pages/Blog";
import { AppContext, appContext } from "./context/app-context";
import Consultants from "./pages/Consultants";
import Company from "./pages/Company";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import "../assets/css/main.css";
import "./app.css";
import Landing from "./pages/Landing";

const App = () => {
  const [language, setLanguage] = useState(appContext.language);

  const messages = {
    en: messages_en,
    fr: messages_fr,
    lu: messages_lu,
  };

  return (
    <AppContext.Provider value={{ language, setLanguage }}>
      <IntlProvider locale={language} messages={messages[language]}>
        <HashRouter>
          <Switch>
            <Route path="/privacy">
              <PrivacyPolicy />
            </Route>
            <Route path="/privacy-notice">
              <PrivacyNotice />
            </Route>
            <Route path="/tikki">
              <Tikki />
            </Route>
            <Route path="/mail">
              <Mail />
            </Route>
            <Route path="/blog">
              <Blog />
            </Route>
            <Route path="/consultants">
              <Consultants />
            </Route>
            <Route path="/company">
              <Company />
            </Route>
            <Route path="/landing">
              <Landing />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </HashRouter>
      </IntlProvider>
    </AppContext.Provider>
  );
};

export default App;
