import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import logoTikki from "../../assets/logo/tikki-text-logo-website.png";
import urielSoberanes from "../../assets/logo/uriel-soberanes.jpg";
import "./styles.css";

const BannerHome = () => (
  <section id="banner">
    <div className="content">
      <header>
        <h2 className="">
          <span className="text-primary">Human centered technology</span> <br />
          crafted to automate your processes
          <br /> and digitize your interactions
        </h2>
        <p>
          <a href="#solutions" className="button primary small scrolly">
            Learn More
          </a>
          <Link to="/company#six" className="button small scrolly">
            Contact Sales
          </Link>
        </p>
      </header>
      <span className="image">
        <img src={urielSoberanes} alt="Uriel" />
      </span>
    </div>
    <a href="#solutions" className="goto-next scrolly">
      <FormattedMessage id="common.next" />
    </a>
  </section>
);

export default BannerHome;
