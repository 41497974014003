import React from "react";
import { FormattedMessage } from "react-intl";
import photoRicardoGomez from "../../assets/img/ricardo-gomez-angel.jpg";
import "./styles.css";

const SectionSolutionsHome = () => (
  <section id="solutions" className="spotlight style1 left">
    <span className="image fit main">
      <img src={photoRicardoGomez} alt="pic 03" />
    </span>
    <div className="content">
      <header>
        <h2 className="text-primary text-uppercase">Tikki</h2>
      </header>
      <div className="box alt">
        <div className="row gtr-uniform">
          <section className="col-12">
            <ul className="alt">
              <li>Timesheet Management</li>
              <li>Customized Invoices</li>
              <li>Financial Dashboard</li>
              <li>Notification Module</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
    <a href="#services" className="goto-next scrolly">
      <FormattedMessage id="common.next" />
    </a>
  </section>
);

export default SectionSolutionsHome;
