import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import HomepageAppHeader from "../../components/HomepageAppHeader";
import Banner from "../../components/Banner";
import SectionOne from "../../components/SectionOne";
import SectionServices from "../../components/SectionServices";
import SectionThree from "../../components/SectionThree";
import SectionTeam from "../../components/SectionTeam";
import SectionSolutions from "../../components/SectionSolutions";
import SectionContact from "../../components/SectionContact";
import SectionResources from "../../components/SectionResources";
import SectionPartners from "../../components/SectionPartners";
import AppFooter from "../../components/AppFooter";
import CookieBanner from "../../components/CookieBanner";
import templateUtil from "../../../importer/templateUtil";
import templateAnimation from "../../../importer/templateAnimation";
import { setPageTitle } from "../../utils";

const Company = ({ intl }) => {
  useEffect(() => {
    setPageTitle(intl.formatMessage({ id: "homepageappheader.menu.company" }));
    templateUtil();
    templateAnimation();
  }, []);

  return (
    <div>
      <HomepageAppHeader />
      <Banner />
      <SectionSolutions />
      <SectionServices />
      <SectionThree />
      <SectionOne />
      <SectionResources />
      <SectionTeam />
      <SectionPartners />
      <SectionContact />
      <AppFooter />
      <CookieBanner />
    </div>
  );
};

export default injectIntl(Company);
