import React from "react";
import { FormattedMessage } from "react-intl";

const TeamMemberBio = ({
  memberImage,
  name,
  description,
  handleOnClickViewMore,
  renderButtonLabel,
  children,
}) => {
  return (
    <section className="col-6 col-12-medium col-12-xsmall">
      <img src={memberImage} alt={name} className="rounded-circle" />
      <h3>{name}</h3>
      <p>
        <FormattedMessage id={description} />
      </p>
      <a className="button" onClick={handleOnClickViewMore}>
        {renderButtonLabel()}
      </a>
      {children}
    </section>
  );
};

export default TeamMemberBio;
