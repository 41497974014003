import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import HomepageAppHeader from "../../components/HomepageAppHeader";
import SectionOne from "../../components/SectionOne";
import SectionThree from "../../components/SectionThree";
import SectionContact from "../../components/SectionContact";
import SectionResources from "../../components/SectionResources";
import SectionPartners from "../../components/SectionPartners";
import AppFooter from "../../components/AppFooter";
import CookieBanner from "../../components/CookieBanner";
import templateUtil from "../../../importer/templateUtil";
import templateAnimation from "../../../importer/templateAnimation";
import { setPageTitle } from "../../utils";
import BannerHome from "../../components/BannerHome";
import SectionSolutionsHome from "../../components/SectionSolutionsHome";
import SectionServicesHome from "../../components/SectionServicesHome";
import SectionMission from "../../components/SectionMission";

const Home = () => {
  const [userInput, setUserInput] = useState("");
  const history = useHistory();
  const password = "opexify";

  useEffect(() => {
    setPageTitle();
    templateUtil();
    templateAnimation();
  }, []);

  const openSecretPage = (e) => {
    setUserInput(`${userInput}${String.fromCharCode(e.keyCode).toLowerCase()}`);
  };

  if (userInput === password) {
    history.push("/mail");
  }

  return (
    <div onKeyDown={openSecretPage} tabIndex="0">
      <HomepageAppHeader />
      <BannerHome />
      <SectionSolutionsHome />
      <SectionServicesHome />
      <SectionMission />
      <SectionPartners />
      <AppFooter />
      <CookieBanner />
    </div>
  );
};

export default Home;
