import React from "react";
import { FormattedMessage } from "react-intl";
import photoHeadway from "../../assets/img/headway.jpg";
import photoPatrickTomasso from "../../assets/img/patrick-tomasso.jpg";

const SectionServicesHome = () => (
  <section id="services" className="spotlight style2 right">
    <span className="image fit main">
      <img src={photoPatrickTomasso} alt="pic 03" />
    </span>
    <div className="content">
      <header>
        <h2 className="text-primary text-uppercase">Uppya</h2>
      </header>
      <ul className="alt">
        <li>Investor reporting documents download</li>
      </ul>
    </div>
    <a href="#one" className="goto-next scrolly">
      <FormattedMessage id="common.next" />
    </a>
  </section>
);

export default SectionServicesHome;
