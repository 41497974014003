import React from "react";
import { FormattedMessage } from "react-intl";
import photoLuca from "../../assets/img/photo-luca-bravo.jpeg";

const SectionMission = () => (
  <section id="one" className="spotlight style1 bottom">
    <span className="image fit main">
      <img src={photoLuca} alt="pic 02" />
    </span>
    <div className="content">
      <div className="container">
        <div className="row">
          <div className="col-12 align-center">
            <h2 className="text-primary text-uppercase">
              Connecting people, system & data
            </h2>
          </div>
          <div className="col-6 col-12-medium">
            <header>
              <h2>
                <FormattedMessage id="sectionone.subtitle" />
              </h2>
            </header>
          </div>
          <div className="col-6 col-12-medium">
            <p>
              <FormattedMessage id="sectionone.description" />
            </p>
          </div>
        </div>
      </div>
    </div>
    <a href="#partners" className="goto-next scrolly">
      <FormattedMessage id="common.next" />
    </a>
  </section>
);

export default SectionMission;
