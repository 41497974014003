import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import rogerTafotie from "../../assets/img/roger-tafotie.jpg";
import tegawendeBissyande from "../../assets/img/tegawende-bissyande.png";
import yannickHuchard from "../../assets/img/yannick-huchard.jpeg";
import michelBarbancey from "../../assets/img/michel-barbancey.jpeg";
import djamilaAouada from "../../assets/img/djamila-aouada.jpg";
import TeamMemberBio from "../TeamMemberBio";
import "./styles.css";

const BIO = {
  DJAMILA: "DJAMILA",
  TEGAWENDE: "TEGAWENDE",
  YANNICK: "YANNICK",
  MICHEL: "MICHEL",
  ROGER: "ROGER",
};

const displayButtonLabel = (value) => {
  return value ? "View Less" : "View More";
};

const SectionTeam = () => {
  const [displayedBio, setDisplayedBio] = useState("");

  const toggleBio = (member) => {
    setDisplayedBio(displayedBio === member ? "" : member);
  };

  return (
    <section id="four" className="wrapper style1 special fade-up">
      <div className="container">
        <h2 className="text-primary text-uppercase">
          <FormattedMessage id="sectionteam.title" />
        </h2>
        <h2>
          <FormattedMessage id="sectionteam.subtitle" />
        </h2>
        <header className="major">
          <br />
          <h3>
            <FormattedMessage id="sectionteam.executive" />
          </h3>
        </header>
        <div className="box alt">
          <div className="row gtr-uniform">
            <section className="col-4 off-4 col-12-medium col-12-xsmall">
              <img
                src={rogerTafotie}
                alt="Roger Tafotie"
                className="rounded-circle alone"
              />
              <h3>Roger Tafotie, PhD</h3>
              <p>
                <FormattedMessage id="sectionteam.roger.description" />
              </p>
              <a
                className="button"
                onClick={() => {
                  toggleBio(BIO.ROGER);
                }}
              >
                {displayButtonLabel(displayedBio === BIO.ROGER)}
              </a>
              {displayedBio === BIO.ROGER && (
                <div className="tafotie-bio">
                  <p>
                    <FormattedMessage id="sectionteam.roger.bio.one" />
                  </p>
                  <p>
                    <FormattedMessage id="sectionteam.roger.bio.two" />
                  </p>
                  <p>
                    <FormattedMessage id="sectionteam.roger.bio.three" />
                  </p>
                  <p>
                    <FormattedMessage id="sectionteam.roger.bio.four" />
                  </p>
                </div>
              )}
            </section>
          </div>
        </div>
        <header className="major">
          <br />
          <h3>
            <FormattedMessage id="sectionteam.advisory" />
          </h3>
        </header>
        <div className="box alt">
          <div className="row gtr-uniform">
            <TeamMemberBio
              memberImage={yannickHuchard}
              name="Yannick Huchard"
              description="sectionteam.yannick.description"
              handleOnClickViewMore={() => {
                toggleBio(BIO.YANNICK);
              }}
              renderButtonLabel={() => {
                return displayButtonLabel(displayedBio === BIO.YANNICK);
              }}
            >
              {displayedBio === BIO.YANNICK && (
                <div className="yannick-bio">
                  <p>
                    <FormattedMessage
                      id="sectionteam.yannick.bio.one"
                      values={{
                        link: (
                          <a href="https://www.amase.io/" target="_blank">
                            https://www.amase.io/
                          </a>
                        ),
                      }}
                    />
                  </p>
                  <p>
                    <FormattedMessage id="sectionteam.yannick.bio.two" />
                  </p>
                  <p>
                    <FormattedMessage
                      id="sectionteam.yannick.bio.three"
                      values={{
                        link1: (
                          <a
                            href="https://www.goodregulator.com/"
                            target="_blank"
                          >
                            https://www.goodregulator.com/
                          </a>
                        ),
                        link2: (
                          <a href="https://booktubers-app.com/" target="_blank">
                            https://booktubers-app.com/
                          </a>
                        ),
                        link3: (
                          <a
                            href="http://www.lightssphere.com/"
                            target="_blank"
                          >
                            http://www.lightssphere.com/
                          </a>
                        ),
                      }}
                    />
                  </p>
                  <p>
                    <FormattedMessage id="sectionteam.yannick.bio.more" />{" "}
                    <a
                      href="https://www.linkedin.com/in/yhuchard/"
                      target="_blank"
                    >
                      https://www.linkedin.com/in/yhuchard/
                    </a>
                  </p>
                </div>
              )}
            </TeamMemberBio>
            <TeamMemberBio
              memberImage={michelBarbancey}
              name="Michel Barbancey"
              description="sectionteam.michel.description"
              handleOnClickViewMore={() => {
                toggleBio(BIO.MICHEL);
              }}
              renderButtonLabel={() => {
                return displayButtonLabel(displayedBio === BIO.MICHEL);
              }}
            >
              {displayedBio === BIO.MICHEL && (
                <div className="yannick-bio">
                  <p>
                    <FormattedMessage id="sectionteam.michel.bio.one" />
                  </p>
                  <p>
                    <FormattedMessage id="sectionteam.michel.bio.two" />
                  </p>
                  <p>
                    <FormattedMessage id="sectionteam.michel.bio.three" />
                  </p>
                  <p>
                    <FormattedMessage id="sectionteam.michel.bio.more" />{" "}
                    <a
                      href="https://www.linkedin.com/in/michel-barbancey-4927227/"
                      target="_blank"
                    >
                      https://www.linkedin.com/in/michel-barbancey-4927227/
                    </a>
                  </p>
                </div>
              )}
            </TeamMemberBio>
          </div>
        </div>
        <div className="box alt">
          <div className="row gtr-uniform">
            <TeamMemberBio
              memberImage={djamilaAouada}
              name="Djamila Aouada"
              description="sectionteam.djamila.description"
              handleOnClickViewMore={() => {
                toggleBio(BIO.DJAMILA);
              }}
              renderButtonLabel={() => {
                return displayButtonLabel(displayedBio === BIO.DJAMILA);
              }}
            >
              {displayedBio === BIO.DJAMILA && (
                <div className="aouada-bio">
                  <p>
                    <FormattedMessage id="sectionteam.djamila.bio.one" />
                  </p>
                  <p>
                    <FormattedMessage id="sectionteam.djamila.bio.two" />
                  </p>
                  <p>
                    <FormattedMessage id="sectionteam.djamila.bio.more" />{" "}
                    <a href="https://cvi2.uni.lu/people/" target="_blank">
                      https://cvi2.uni.lu/people/
                    </a>
                  </p>
                </div>
              )}
            </TeamMemberBio>
            <TeamMemberBio
              memberImage={tegawendeBissyande}
              name="Tegawendé Bissyand"
              description="sectionteam.tegawende.description"
              handleOnClickViewMore={() => {
                toggleBio(BIO.TEGAWENDE);
              }}
              renderButtonLabel={() => {
                return displayButtonLabel(displayedBio === BIO.TEGAWENDE);
              }}
            >
              {displayedBio === BIO.TEGAWENDE && (
                <div className="tagewende-bio">
                  <p>
                    <FormattedMessage id="sectionteam.tegawende.bio.one" />
                  </p>
                  <p>
                    <FormattedMessage id="sectionteam.tegawende.bio.two" />
                  </p>
                  <p>
                    <FormattedMessage id="sectionteam.tegawende.bio.three" />
                  </p>
                  <p>
                    <FormattedMessage id="sectionteam.tegawende.bio.more" />{" "}
                    <a href="https://bissyande.github.io" target="_blank">
                      https://bissyande.github.io
                    </a>
                  </p>
                </div>
              )}
            </TeamMemberBio>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionTeam;
